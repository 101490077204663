export const crclr = require('../assets/crclr.png').default;
export const radZigImg = require('../assets/radZigImg.png').default;
export const whtDot = require('../assets/whtDot.png').default;
export const katorLogo = require('../assets/katorLogo.png').default;
export const textLogo = require('../assets/textLogo.png').default;

export const arrowbackward = require('../assets/arrowbackward.svg').default;
export const whitefullwave = require('../assets/whitefullwave.svg').default;
export const solidhalfcircle = require('../assets/solidhalfcircle.svg').default;
export const dotGrid4x6Image = require('../assets/4x6dotgrid.svg').default;
export const arrowForwardIcon = require('../assets/arrowforward.svg').default;

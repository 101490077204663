import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button
} from "@mui/material";
import { menubarIcon, katorlogo, fullsolidcircle, fullwaveimage, halfwaveimage, arrowForwardIcon, footerkatorlogo, footerArrForwIcon, dotGridImage, verticalHalfCircle, dotGrid5x6Image } from "./assets";
import { useNavigate } from "react-router-dom";

// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Box sx={styles.mainContainer}>
        <Box sx={styles.nav}>
          <Box component="img" src={menubarIcon} alt="menuIcon" />
        </Box>
        <Box sx={styles.navButtonContainer}>
          <Button sx={styles.navButton} ><Typography sx={styles.navBtnTypograpy}>Take the Quiz</Typography></Button>
          <Button sx={styles.navButton} ><Typography sx={styles.navBtnTypograpy}>About Us</Typography></Button>
          <Button sx={styles.navButton} ><Typography sx={styles.navBtnTypograpy}>Contact Us</Typography></Button>
        </Box>
        <Box sx={styles.logoContainer}>
          <Box component="img" sx={styles.katorlogo} src={katorlogo} alt="kator" />
        </Box>
        <Box sx={styles.firstdesignpatterncontainer}>
          <Box sx={styles.firstdesignpattern}>
            <Box component="img" sx={styles.dotgrid6X6} src={dotGridImage} alt="dotgrid6x6" />
            <Box component="img" sx={styles.fullcircle} src={fullsolidcircle} alt="fullcircle" />
            <Box component="img" sx={styles.halfwaveImage} src={halfwaveimage} alt="halfwaveimage" />
          </Box>
        </Box>
        <Box sx={styles.titleContainer}>
          <span style={styles.title}>{configJSON.landingPageTitle}</span>
          <span style={styles.subtitle}>{configJSON.landingPageSubtitle}</span>
        </Box>

        <Box sx={styles.secondbagImageContainer}>
          <Box component="img" sx={styles.dotgrid5X6} src={dotGrid5x6Image} alt="dotgrid" />
          <Box sx={styles.btnContainer}>
            <button style={styles.textBtn}>
              {configJSON.Begin}
              <Box component="img" style={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
            </button>
          </Box>
        </Box>
        <Box sx={styles.halfcirclecontainer}>
          <Box component="img" style={styles.verticalHalfCircle} src={verticalHalfCircle} alt="halfcircle" />
        </Box>
        <Box sx={styles.titleContainer}>
          <span style={styles.title}>{configJSON.aboutus}</span>
          <span style={styles.subtitle}>{configJSON.aboutusDescription}</span>
        </Box>
        <Box sx={{ ...styles.btnContainer, margin: "20px 16px" }}>
          <button style={styles.textBtn}>
            {configJSON.Start}
            <Box component="img" style={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
          </button>
        </Box>
        <Box sx={styles.contactFormWrapper}>
          <Box component="img" sx={styles.fullwaveImageBehindForm} src={fullwaveimage} alt="fullwaveimage" />
          <Box sx={styles.contactFormContainer}>
            <Box sx={styles.formTitleContainer}>
              <span style={styles.formtitle}>{configJSON.contactus}</span>
              <span style={styles.formSubtitle}>{configJSON.contactusDescription}</span>
            </Box>
            <Box sx={styles.formContainer}>
              <Box sx={styles.groupInputWithLabel}>
                <label style={styles.textlabel}>{configJSON.fullName}</label>
                <input name='fullname' style={styles.textinput} placeholder='John Smith' value={this.state.fullname} onChange={this.handleInputChange} />
              </Box>
              <Box sx={styles.groupInputWithLabel}>
                <label style={styles.textlabel}>{configJSON.Email}</label>
                <input name='email' style={styles.textinput} placeholder='name@email.com' value={this.state.email} onChange={this.handleInputChange} />
              </Box>
              <Box sx={styles.groupInputWithLabel}>
                <label style={styles.textlabel}>{configJSON.contactusTextareaLabel}</label>
                <textarea name='description' style={styles.textareainput} placeholder='You can write your questions here' value={this.state.description} onChange={this.handleInputChange} />
                <span style={styles.descriptionTextLength}>{`${this.state.description.length}/50`}</span>
              </Box>
              <Box sx={styles.contactBtnContainer}>
                <button style={{ ...styles.textBtn, color: "#F1ECE4" }}>
                  {configJSON.Send}
                  <Box component="img" style={styles.forwArrIcon} src={footerArrForwIcon} alt="rightArrow" />
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.footerContainer}>
          <Box sx={styles.footerContent}>
            <Box sx={styles.lefttext}>
              <Box sx={styles.subHeadFooterLogo}>
                <img style={styles.footerkatorlogo} src={footerkatorlogo} alt="kator" />
              </Box>
              <Box component="span" sx={styles.leftfooterText}>{configJSON.privacypolicytext}</Box>
            </Box>
            <Box style={styles.righttext}>
              <Box component="span" sx={styles.rightfooterText}>{configJSON.katortext}</Box>
              <Box component="span" data-testid="terms-link" onClick={() => this.handleTermAndCondition()} sx={styles.rightfooterText2}>{configJSON.termsconditionstext}</Box>
            </Box>
          </Box>
        </Box>
      </Box >
    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    backgroundColor: "#F1ECE4",
    position: "relative" as const,
    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
  nav: {
    display: "none",
    "@media (max-width: 575px)": {
      backgroundColor: "transparent",
      height: "48px",
      alignItems: "center",
      margin: "0px 0px 0px 8px",
      display: "flex",
    },
  },
  navButtonContainer: {
    marginLeft: "10rem",
    marginTop: "1.5rem",
    gap: "2rem",
    display: "flex",
    "@media (max-width: 575px)": {
      display: "none",
    },
  },
  navButton: {
    textTransform: "inherit",
    color: "#948E8D",
    padding: "10px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#524948",
    },
  },
  navBtnTypograpy: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
  },
  logoContainer: {
    position: "absolute" as const,
    top: "1.7rem",
    left: "1.5rem",

    "@media (max-width: 575px)": {
      position: "static" as const,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      margin: "0px 0px 20px 0px",
    },
  },
  katorlogo: {
    width: "108px",
    height: "33px",
  },
  firstdesignpatterncontainer: {
    "@media (min-width: 576px)": {
      marginTop: "-6rem",
    },
  },
  firstdesignpattern: {
    height: "12.5rem",
    margin: "2rem 0rem",
    "@media (max-width: 575px)": {
      position: "relative" as const,
    }
  },
  dotgrid6X6: {
    position: "absolute" as const,
    width: "6.25rem",
    height: "6.25rem",
    right: "16rem",
    top: "6.5rem",
    zIndex: "1",
  },
  fullcircle: {
    position: "absolute" as const,
    right: "10rem",
    top: "1rem",
    width: "10.25rem",
    height: "10.25rem",
  },
  halfwaveImage: {
    position: "absolute" as const,
    right: "0rem",
    top: "1rem",
    width: "15rem",
    height: "10rem",
    "@media (max-width: 575px)": {
      top: "2rem",
    },
  },
  titleContainer: {
    "@media (max-width: 575px)": {
      margin: "0rem",
    },
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "start",
    padding: "8px 24px",
    margin: "0rem 5rem"
  },
  title: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
    margin: "0px",
  },
  subtitle: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 16px",
    "@media (min-width: 576px)": {
      margin: "0rem 10rem 0rem 0rem"
    },
  },
  contactBtnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px",
    "@media (min-width: 576px)": {
      margin: "0rem 2rem 0rem 0rem"
    },
  },
  secondbagImageContainer: {
    display: "flex",
    justifyContent: "end",
    position: "relative" as const,
    marginTop: "2rem",
    "@media (max-width: 575px)": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "3rem",
    },
  },
  dotgrid5X6: {
    position: "absolute" as const,
    top: "8rem",
    left: "0rem",
    width: "6.25rem",
    height: "6.25rem",
    "@media (max-width: 575px)": {
      position: "static" as const,
      width: "6.25rem",
      height: "6.25rem",
    },
  },
  halfcirclecontainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  verticalHalfCircle: {

  },
  textBtn: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  forwArrIcon: {
    width: "24px",
    height: "24px",
  },
  contactFormWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "auto",
    marginTop: "5rem"
  },
  fullwaveImageBehindForm: {
    position: "absolute" as const,
    "@media (max-width: 575px )": {
      width: "50%",
      height: "auto",
      top: "-3rem",
      left: "0",
    },
    width: "auto",
    left: "-5rem",
    top: "-4rem",
  },
  contactFormContainer: {
    "@media (max-width: 575px)": {
      margin: "0rem",
    },
    backgroundColor: "#706968",
    borderRadius: "40px 40px 0px 0px",
    margin: "0rem 5rem",
    padding: "50px 0px",
    position: "relative" as const,
    zIndex: "1",
  },
  formTitleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "8px",
    padding: "8px 24px",
  },
  formtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
  },
  formSubtitle: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "24px",
    padding: "24px 24px 0px",
  },
  groupInputWithLabel: {
    display: "flex",
    flex: "1",
    minWidth: "100%",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  textlabel: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
    // margin: "0px",
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  textareainput: {
    width: "100%",
    height: "100px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4B2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  descriptionTextLength: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "right" as "right",
  },
  footerContainer: {
    backgroundColor: "#524948",
    height: "110px",
    alignItems: "center",
    display: "flex",
    '@media (max-width: 660px)': {
      alignItems: "center",
      display: "flex",
      height: "80px",
    }
  },
  footerContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "86%",
    alignItems: "center",
    margin: "auto",
    '@media (max-width: 660px)': {
      width: "86%",
    }
  },
  lefttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  righttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  subHeadFooterLogo: {
    width: "auto",
    height: "32px",
    '@media (max-width: 660px)': {
      width: "54px",
      height: "17px",
    }
  },
  footerkatorlogo: {
    width: "100%",
    height: "100%",
  },
  leftfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left" as "left",
    '@media (max-width: 660px)': {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    }
  },
  rightfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "right" as "right",
    '@media (max-width: 660px)': {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    }
  },
  rightfooterText2: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
    textAlign: "right" as "right",
    '@media (max-width: 660px)': {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    }
  }
};
// Customizable Area End

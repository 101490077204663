import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@mui/material";
import { katorlogo, menubarIcon, verticalHalfCircle } from "./assets";
import CloseIcon from '@mui/icons-material/Close';

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={styles.container}>
        <Box sx={styles.innerContainer}>
          <Box sx={styles.desktopHeader}>
            <Box sx={styles.logoContainer}>
              <img style={styles.katorlogo} src={katorlogo} alt="katorlogo" />
            </Box>
            <Box sx={styles.headerItem}>
              <Typography sx={styles.navItem}>Take The Quiz</Typography>
              <Typography sx={styles.navItem}>About Us</Typography>
              <Typography sx={styles.navItem}>Contact Us</Typography>
            </Box>
            <Box sx={styles.circleImgHead}>
              <img src={verticalHalfCircle} alt="" />
            </Box>
          </Box>
          {
            this.state.isOpenMenu === true ? "" :
              <Box sx={styles.mobileMenuIcon}>
                <img src={menubarIcon} alt="menubarIcon" onClick={this.taggleMenuIcon} />
              </Box>
          }
          {
            this.state.isOpenMenu &&
            <Box>
              <Box sx={styles.menuContainer}>
                <Typography component="span" sx={styles.menuSize}>MENU</Typography>
              </Box>
              <Box sx={styles.closeButton}>
                <Box onClick={this.taggleMenuIcon}><CloseIcon sx={styles.closeIcon} /></Box>
              </Box>
              <Box sx={ styles.menuHead }>
                <Box sx={ styles.menuSubHead }>
                  <Box sx={ styles.menuItemHead }>
                    <Typography component="span" sx={styles.menuText}>About Us</Typography>
                  </Box>
                  <Box sx={styles.menuItemHead }>
                    <Typography component="span" sx={styles.menuText}>Take The Quiz</Typography>
                  </Box>
                  <Box sx={ styles.menuItemHead }>
                    <Typography component="span" sx={styles.menuText}>Contact Us</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.fixedFooter}>
                <Typography sx={styles.termsText}>Terms and Conditions</Typography>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
  },
  innerContainer: {
    alignItems: "center",
    backgroundColor: "#F1ECE4",
  },
  desktopHeader: {
    display: { xs: 'none', sm: 'flex' },
    width: "100%",
    height: "80px",
    paddingTop: "10px",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginLeft: { lg: "100px", md: '100px', sm: "70px" },
    alignItems: "center",
  },
  headerItem: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  navItem: {
    marginLeft: "120px",
    fontSize: "14px",
    color: "#827c7c",
    fontWeight: "600",
    '&:hover': {
      color: 'black',
    },
    '@media (max-width: 860px)': {
      marginLeft: "100px",
    },
    '@media (max-width: 660px)': {
      marginLeft: "40px",
    },
  },
  circleImgHead: {
    zIndex: "1",
    position: "absolute",
    right: "230px",
    transform: "rotate(270deg)",
    marginTop: "-16px"
  },
  mobileMenuIcon: {
    display: { xs: 'flex', sm: 'none' },
    marginLeft: "8px",
  },
  menuContainer: {
    textAlign: "center",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "space-around",
  },
  menuText: {
    fontSize: "20px",
    color: "black",
    marginTop: "-20px",
    '&:hover': {
      color: 'black',
      borderBottom: "2px solid black",
      fontWeight: "600",
      fontSize: "22px",
    },
  },
  closeButton: {
    zIndex: "1",
    position: "absolute",
    top: "42.5px",
    right: "20px",
  },
  fixedFooter: {
    position: "fixed",
    bottom: "10px",
    textAlign: "center",
    left: "50%",
    transform: "translateX(-50%)",
    borderBottom: "1px solid black",
  },
  termsText: {
    marginBottom: "-5px",
  },
  katorlogo: {
    width: "150px",
    height: "50px"
  },
  menuSize: {
    fontFamily: "sans serif",
    fontSize: "14px",
  },
  closeIcon: {
    height: "21px",
    width: "21px"
  },
  menuHead: {
    display: "flex", 
    alignItems: "center", 
    height: "100vh"
  },
  menuSubHead: {
    width: "100%", 
    textAlign: "center"
  },
  menuItemHead: {
    flexDirection: "column", 
    margin: "20px 0px"
  }
};

// Customizable Area End

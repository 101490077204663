import React from "react";
// Customizable Area Start
// import styled from "styled-components";
import { arrowbackward, whitefullwave, solidhalfcircle, arrowForwardIcon, dotGrid4x6Image } from "./assets";
import Captcha from "../../../blocks/captcha/src/Captcha.web";
// const MainContainer = styled.div`
//   @media (min-width: 576px) {
//     display: flex;
//     margin: auto;
//     width: 390px;
//   }

//   @media (max-width: 575px) {
//     width: 100%;
//   }
// `;
// Customizable Area End

import MultipageFormsController, {
  Props,
  configJSON
} from "./MultipageFormsController";
import { ResolveCondition, ResolveConditionForMutyPara } from "../../utilities/src/CustomHelpers";
// packages/blocks/utilities/src/CustomHelpers
export default class DefaultZipCodeWeb extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <div style={styles.mainContainer} className="dummy">
        <div style={styles.container}>
          <nav style={styles.nav}>
            <img src={arrowbackward} alt="menuIcon" />
          </nav>
          <div style={styles.titleContainer}>
            <img style={styles.whitefullwave} src={whitefullwave} alt="whitefullwave" />
            <span style={styles.title}>{configJSON.defaultZipCodeTitle}</span>
            <span style={styles.subtitle}>{configJSON.defaultZipCodeSubtitle1}</span>
            <span style={styles.subtitle}>{configJSON.defaultZipCodeSubtitle2}</span>
          </div>
          <div style={styles.formContainer}>
            <div style={styles.groupInputWithLabel} className="dummy">
              <img style={styles.dotGrid4x6Image} src={dotGrid4x6Image} alt="dotGrid4x6Image"/>
              <label style={styles.textlabel}>{configJSON.defaultZipCodeInputLabel}</label>
              <input name='zipcode' style={styles.textinput} data-test-id='txtInputzipcode' placeholder="e.g. 10001" value={this.state.zipcode} onChange={this.handleInputChange} />
              {
ResolveConditionForMutyPara(this.state.zipcode!== "" ,!this.state.vaildZipcode, <span style={styles.errorDescription}>{configJSON.defaultZipCodeInputErrorDescription}</span>)
              }
            </div>
            {ResolveCondition(this.state.vaildZipcode,<div><Captcha navigation={this.props.navigation} id={this.props.id} /></div>,'')}

            
            <div style={styles.checkboxContainer} id="checkboxContainer">
              <input name="termsAndConditionCheckbox" type="checkbox" />
              <span style={styles.checkboxDescription}>{configJSON.defaultZipCodeTermsAndConditionDescription}</span>
            </div>
            <div style={{ ...styles.checkboxContainer, marginBottom: "5rem"}}>
              <input name="acknowledgeCheckbox" type="checkbox" />
              <span style={styles.checkboxDescription}>{configJSON.defaultZipCodeAcknowledgeDescription}</span>
            </div>
            <div style={{ ...styles.btnContainer, margin: "0px" }}>
              <button style={{ ...styles.textBtn, color: "#948E8D" }}>
                {configJSON.Submit}
                <img style={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
              </button>
            </div>
          </div>
          <div style={styles.solidhalfcirclecontainer} className="dummy">
            <img style={styles.solidhalfcircle} src={solidhalfcircle} alt="solidhalfcircle" />
          </div>
        </div>
       
       
      </div >

    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    display: "flex",
    margin: "auto",
    width: "390px",
    "@media (max-width: 575px)": {
      width: "100%",
    },
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#F1ECE4",
    position: "relative" as const,
  },
  nav: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    margin: "0px 0px 0px 16px"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "start",
    padding: "8px 24px",
    position: "relative" as const,
    marginTop: "7rem",
  },
  whitefullwave: {
    position: "absolute" as const,
    top: "-6rem",
    right: 0,
    width: "60%",
    height: "auto",
  },
  title: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
    margin: "0px",
    zIndex: "1",
  },
  subtitle: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
    zIndex: "1",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "24px",
    padding: "24px 24px 0px",
    margin: "3rem 0rem",
  },
  dotGrid4x6Image:{
    position: "absolute" as const,
    right: "-2rem",
  },
  groupInputWithLabel: {
    display: "flex",
    flex: "1",
    minWidth: "100%",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "4px",
    marginBottom: "3rem",
    position: "relative" as const,
  },
  textlabel: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    outline: "none",
    opacity: "0px",
    backgroundColor: "#FFFFFF",
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    zIndex: "1",
  },
  errorDescription: {
    width: "100%",
    display: "flex",
    justifyContent: "start" as "start",
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "left" as "left",
  },
  checkboxContainer: {
    display: "flex",
    gap: "12px",
    "input[type='checkbox']:checked": {
      backgroundColor: "#524948",
    },
  },
  checkboxDescription: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left" as "left",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 16px"
  },
  textBtn: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  forwArrIcon: {
    width: "24px",
    height: "24px",
  },
  solidhalfcirclecontainer: {
    position: "absolute" as const,
    bottom: "0rem",
    left: "0rem",
  },
  solidhalfcircle: {
    width: "10rem",
  }
};
// Customizable Area End

import React from "react";

import {
  Box,
  Button,  
  // Customizable Area Start
  RadioGroup,
  FormControlLabel,
  Radio,
  styled

  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import Slider from '@mui/material/Slider';

import { radZigImg, whtDot, crclr, katorLogo, textLogo }from "./assets";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Customizable Area End

import MultipageFormsController, {
  Props,
 
} from "./MultipageFormsController";



export default class DetailedQuiz extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      }, {
        value: 10,
        label: '10',
      },

    ];

    const qut = [
      { qut: 'How strongly do you agree that the government should increase funding for border security measures, such as building a wall or enhancing surveillance?' },
      { qut: 'Should taxes be decreased overall?' },
      
    ]

    return (
      // Customizable Area Start
      <>
        <StyledWrapper>
          {this.state.navigationNum == 0 &&
            <div className="mainFormPage" >

              <div className="container">
                <div className="topBacckButton "><button  data-test-id='backNavMnu' className="backNavMnu" onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow" /></button></div>
                <div className="childCompont ">

                  <div className="howWouldAndPera pera">

                    <div className="howWouldHead">
                      <h2> How would you like to get recommended candidates? </h2>

                    </div>
                    <div className="howWouldPera ">
                      <p>we'll ask you a few questions to generate a list of recommended candidates. you can choose between a quick quiz for faster results or a detailed quize for the most accurate recommendations."Blod the words "fast results" and accurate recommendations </p>
                    </div>
                  </div>
                  <div className="quizeType ">

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      data-test-id='radiobtnchange'
                      onChange={this.handleChange}


                    >
                      <FormControlLabel
                        className='firstQuize'

                        style={{
                          ...webStyle.firstQuize,
                          ...(this.state.selectedValue === 'Simplified Quiz' ? webStyle.firstQuizeSelected : {}),
                        }}

                        value="Simplified Quiz" control={<Radio
                          sx={{
                            paddingTop: '0px',                           
                          }}
                        />
                        }

                        label={
                          <div >
                            <span className="radioMAnLable">Simplified Quiz</span>
                            
                            <div className="radioLabel">
                              It will take just 5 minutes.
                            </div>
                          </div>
                        }
                        sx={{
                          alignItems: 'flex-start',
                          '& .MuiFormControlLabel-label': {
                            marginTop: '2px',
                          },
                          color: this.state.selectedValue === 'Simplified Quiz' ? 'white' : 'black',
                          '& .radioLabel':{
                            color: this.state.selectedValue === 'Simplified Quiz' ? 'white' : 'black',

                          },
                          '& .MuiButtonBase-root-MuiRadio-root .Mui-checked':{
                            color: this.state.selectedValue === 'Simplified Quiz' ? 'white' : '',
                          }
                          
                        }}
                      />
                      <FormControlLabel
                        style={{
                          ...webStyle.firstQuize,
                          ...(this.state.selectedValue === 'Detailed Quiz' ? webStyle.firstQuizeSelected : {}),
                        }}
                            className='firstQuize'
                           value="Detailed Quiz" control={
                           <Radio


                          sx={{
                            paddingTop: '0px',

                            '&  .Mui-checked': {
                              color: this.state.selectedValue === 'Detailed Quiz' ? 'white' : '',
                            },
                            '& .MuiButtonBase-root-MuiRadio-root':{
                              color: this.state.selectedValue === 'Detailed Quiz' ? 'white' : '',

                            }
                          }}
                        />
                        }
                        label={
                          <div >
                            <span className="radioMAnLable DetlQut">Detailed Quiz</span>
                            <div className="radioLabel" >
                              It will take 15 minutes.
                            </div>
                          </div>
                        }
                        sx={{
                          alignItems: 'flex-start',
                          '& .MuiFormControlLabel-label': {
                            marginTop: '2px',
                          },
                          color: this.state.selectedValue === 'Detailed Quiz' ? 'white' : 'black',
                          '& .radioLabel':{
                            color: this.state.selectedValue === 'Detailed Quiz' ? 'white' : 'black',

                          }
                        }}
                      />
                    </RadioGroup>
                  </div>

                  <div className="contnuBtn"  id="CountBtn" >
                    <Button variant="contained" data-test-id='hideOnMobl' className="hideOnMobl" onClick={this.navigationNumChange} sx={{textTransform:'capitalize'}} >Continue <EastIcon className="lftTrght" />
                    </Button>
                    <Button variant="text" data-test-id='showOnMobl' className="showOnMobl" onClick={this.navigationNumChange} sx={{textTransform:'capitalize'}}
                    >Continue <EastIcon />
                    </Button>

                  </div>
                </div>
              </div>
            </div>
          }
          {this.state.navigationNum == 1 && <div className="mainFormPageSecond"  >

            <div className="container">
              <div className="topBacckButton"><button data-test-id='backNavMnuTw' onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow " /></button></div>
              <div className="childCompont mnuSecAllContnt">

                <div className="showOnMoblLogo">
                  <img src={katorLogo} alt="logo" />
                </div>
                <div className=" seconMnuHandel "  id="secondComponent">

                  <div className=" seconMnuHead  ">
                    <h2>Select your interests </h2>

                  </div>
                  <div className="howWouldPera seconMnuHandelPera ">
                    <p>Select all the topics that matter to you, and we'll ask relevant questions to help you choose the best candidates</p>
                  </div>
                </div>
                <div className="quizeType  buttonGroup " id="buttonGroupSecondCompont">
                  {this.state.selectedBtn.map((cvl:any,index:number)=>{
                    return <>
                    <Button key={index} variant="outlined" data-test-id="quizbutton"  value={cvl.value} className= {cvl.clk?"buttonClk":'button'}
                   onClick={()=>{this.selectedBtnChange(cvl.value)}}>{cvl.value}</Button></>
                  })}
                  
                </div>

                <div className="contnuBtn "  >
                  <Button variant="contained" data-test-id='hideOnMoblsubmit' className="hideOnMobl" onClick={this.navigationNumChange} 
                  sx={{textTransform:'capitalize',fontSize:'20px',fontWeight:'700'}}>Submit <EastIcon className="lftTrght" />
                  </Button>
                  <Button variant="text" data-test-id='showOnMoblsubmit' className="showOnMoblSubmt" onClick={this.navigationNumChange}  sx={{textTransform:'capitalize',fontSize:'16px',fontWeight:'700'}}>Submit <EastIcon sx={{width: '33.11px',height: '32.27px'}} />
                  </Button>

                </div>
              </div>
            </div>
          </div>}

          {this.state.navigationNum == 2 &&
            <div className="mainFormPageQuz questionSectn dumyQunClas" >
              <div className="container">
                <div className="topBacckButton"><button data-test-id='backNavMnuQ' onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow" /></button></div>
                <div className=" QuzAllCont">

                  <div className="showOnMoblorDektLogo">
                    <img src={katorLogo} alt="logo" />
                  </div>
                  <div className="quzeModel" id="quzeModel">

                    <div className="logoTxtQuSkp" id="Katorlogo">
                      <div className="textLogo">
                        <img src={textLogo} alt="logo" />
                      </div>
                      <span className="logoTxtQuSkpOne" id="logoTxt">TAXES</span>
                      <span className="logoTxtQuSkpSec">Question 1/10</span>
                      <span className="logoTxtQuSkpThrd">SKIP SUBJECT</span>
                    </div>
                    <div className="quzeQ" >
                      <h3>{qut[this.state.numQut].qut}</h3>
                      <span className="votFrQt" id="dummyVot">1 (disagree) - 10 (agree)</span></div>
                    <div className="votSelecSld">
                      <Box sx={{ width: 300, color: '#DFD5C4' }}>
                        <Slider
                          aria-label="Always visible"
                          defaultValue={0}

                          marks={marks}
                          max={10}
                          valueLabelDisplay="on"
                          sx={{
                            "& .MuiSlider-markLabel": {
                              display: 'none'
                            },
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: '#F1ECE4', color: '#524948', fontFamily: 'Inter',fontSize:'22px',fontWeight:'400',
                              " @media(max-width:768px)": {fontSize:'20px',fontWeight:'400',
                              }
                            }, "& .MuiSlider-rail": {
                              backgroundColor: '#DFD5C4', height: '17px', color: '#FFFFFF'
                            }, "& .MuiSlider-track": {
                              backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', height: '17px'
                            }, "& .MuiSlider-thumb ": {
                              backgroundColor: '#DFD5C4', boxShadow: '#DFD5C4'
                            }, "& .MuiSlider-root": {
                              backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', color: '#DFD5C4', height: '17px'

                            }, "& .MuiSlider-thumb:hover": {
                              color: '#DFD5C4', boxShadow: '#DFD5C4'
                            }
                          }
                          }
                        />
                      </Box>
                    </div>

                    <div className="prvNxtBtn" id="prvNxtBtn">
                      <button type="button" data-test-id="chngePrvQut" onClick={this.chngePrvQut}>
                        <WestIcon  className="prvNxtBtnb"/>
                      </button>
                      <button type="button" id="buttonChngQutn" data-test-id="chngeQut" onClick={this.chngeQut} >
                        <EastIcon  className="prvNxtBtnb" />
                      </button>
                    </div>

                  </div>


                </div>
              </div>

            </div>
          }
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainFormPage: {
    backgroundColor: '#fcf2d4',
    height: '100vh'
  },
  childCompont: {
    margin: 'auto',
    width: '45%',
    "& @media(max-width:768px)": {
      width: '100%',
      backgroundColor: 'black'
    }
  },
  howWouldHead: {
    width: '70%'
  },
  firstQuize: {
    border: '1px solid #524948',
    borderRadius: '6px',
    display: 'flex',
    cursor: 'pointer',
    padding: '10px',


  }, firstQuizeHover: {
    backgroundColor: '#f0e68c',
  },
  firstQuizeActive: {
    backgroundColor: '#524948',
  },
  firstQuizeSelected: {
    backgroundColor: '#524948',

  },
  radioChecked: {
    backgroundColor: '#524948',

  },
  radioUnchecked: {
    backgroundColor: 'transparent'
  }
  , contnuBtn: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center"
  }


};


const StyledWrapper = styled(Box)({
  "& .mainFormPage": {

    backgroundColor: '#F1ECE4',
    backgroundImage: `url(${radZigImg}), url(${whtDot}), url(${crclr})`,
    backgroundPosition: 'right 15px, 83px 581px, -69px 631px',
    backgroundSize: '380.31px auto,  171px 171px, 272px 272px',
    backgroundRepeat: 'no-repeat',
    width: '100%',


    "@media(max-width:768px)": {
      padding: '0 0px', width: '100%',
      height: '100vh',
      backgroundImage: `url(${radZigImg}), url(${whtDot}), url(${crclr})`,
      backgroundPosition: '-56px 650px, 292px 95px, -59px 810px',
      backgroundSize: '240px 160.5px,  100px 100px, 182px 182px',
      backgroundRepeat: 'no-repeat',

    }
  },
  "&container": {
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    padding: '0 15px',
    height: 'auto',

  },

  "& .childCompont": {
    margin: ' auto',
    width: '597px',
    paddingTop: '5px',

    "@media(max-width:768px)": {
      paddingTop: '20px',
      width: '99%',
      overflowX: 'hidden'
    }
  },
  "& .howWouldAndPera": {
    width: '597px',
    padding: '0px 24px',
    marginTop: '35px',
    "@media(max-width:768px)": {
      width: '324px',
      marginTop: '0px',
    }
  },
  "& .howWouldPera ": {
    marginTop: '0px',
    "@media(max-width:768px)": {
      width: '326px',
    }
  },
  "& .howWouldPera p": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400', lineHeight: '24px',
    marginTop: '0%',
    color: '#706968',
    "@media(max-width:768px)": {
      width: '326px',

    }
  },
  "& .howWouldHead": {
    width: '597px',
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#524948', paddingTop: '0px',
    "@media(max-width:768px)": {
      width: '326px',
      fontSize: '30px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color: '#524948', paddingTop: '0px'
    }
  },
  "& .firstQuize": {
    border: '1px solid #524948',
    borderRadius: '10px',
    display: 'flex',
    cursor: 'pointer',
    padding: '10px',
    marginBottom: '24px',
    marginRight: '21px', marginLeft: '22px',
    "@media(max-width:768px)": {
      borderRadius: '6px',
      padding: '16px 12px',
      paddingRight: '20px',
      paddingLeft: '15px',
    }

  },

  "& .firstQuizeHover": {
    backgroundColor: '#f0e68c',
  },
  "& .firstQuizeActive": {
    backgroundColor: '#524948',
  },
  "& .firstQuizeSelected": {
    backgroundColor: '#524948',

  },
  "& .radioChecked": {
    backgroundColor: '#524948',

  },
  "& .radioUnchecked": {
    backgroundColor: 'transparent'
  }
  , "& .contnuBtn": {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",

  }, "& .showOnMobl": {
    display: 'none',
    "@media(max-width:768px)": {
      position: "absolute",
      bottom: '0%', right: '5%',
      display: 'flex',
      textAlign: 'center',
      borderColor: 'transparent',
      color: 'black',
      fontSize: '16px',
      gap: '20px',fontWeight:'700'
    }
  },
  "& .quizeType": {
    marginTop: '70px',
    "@media(max-width:768px)": {
      marginTop: '20px',
    }


  },
  "& .hideOnMobl:hover": {
    backgroundColor: '#6699B1',   

  },
  "& .hideOnMobl": {
    display: 'flex',
    justifyContent: 'center',
    alignItem: "center",
    width: '256px',
    height: '56px',
    fontSize: '20px',
    margin: 'auto',
    marginTop: '20px',
    borderRadius: '8px',
    backgroundColor: '#6699B1',
    padding: '10px 16px',
    gap: '8px',fontWeight:'700',
    "@media(max-width:768px)": {
      display: 'none',
    }
  },'& .selectYTtBtn':{
marginBottom:'200px',
  }
  , "& .lftTrght": {
    width: '24px',
    height: '24px',
  },'& .radioMAnLable':{
    fontSize: "16px",   fontFamily:'Inter',fontWeight:'400'


  },
  "& .radioLabel": {
    fontSize: "14px",
    color: "#777",
    marginLeft: '-28px',
    marginTop: '5px',fontFamily:'Inter',fontWeight:'400'
  }
  , "& .topBacckButton": {
    color: 'black',
    margin: 'auto',
    width: '80%',
    paddingTop: '120px',
    "@media(max-width:768px)": {
      paddingTop: '20px',
      margin: 'auto',
      width: '100%',
    }
  },
  "& .topBacckButton button .lftarow": {
    color: 'black',
    cursor: 'pointer'
  },
  "& .topBacckButton button": {
    backgroundColor: "transparent",
    borderStyle: 'none',

  },
  "& .topBacckButton button:hover": {
    backgroundColor: "transparent",
    borderStyle: 'none'
  },
  "& .showOnMoblLogo": {
    display: 'none',
    "@media(max-width:768px)": {
      display: 'flex',
      justifyContent: 'center',
      alignItem: "start",
    }
  },
  "& .showOnMoblLogo img": {
    width: '106px',
    height: '33.3px'
  },
  "& .mainFormPageSecond": {
    backgroundColor: '#F1ECE4',
    backgroundImage: `url(${radZigImg}), url(${whtDot}), url(${crclr})`,
    backgroundPosition: 'right 15px, 83px 581px, -69px 631px',
    backgroundSize: '380.31px auto,  171px 171px, 272px 272px',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    "@media(max-width:768px)": {
      padding: '0px 0px', width: '100%',
       background: 'none', backgroundColor: '#F1ECE4',
    }
  },
  "& .mnuSecAllContnt": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
    flexDirection: "column", textAlign: 'center',
    margin: ' auto',
    width: '597px', paddingTop: '5px',
    "@media(max-width:768px)": {
      paddingTop: '20px',
      margin: ' auto',
      width: '99%',
      overflowX: 'hidden', display: 'flex', justifyContent: 'center',
      alignItem: "center",
      flexDirection: "column", textAlign: 'center',
    }
  },
  "& .buttonGroup": {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
    alignItem: "center",
    marginTop: '20px',
    width: '597px',
    marginright: '12px',
    "@media(max-width:768px)": {
      width: '100%', display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      justifyContent: 'center',
      alignItem: "center",
    }
  },'& .contnuBtnForSecond':{
    display: 'flex',
    justifyContent: 'end',
    alignItem: "center",margin:'30px 0'
  },'& .showOnMoblSubmt':{
    display: 'none',
    "@media(max-width:768px)": {
      display: 'flex',
      textAlign: 'center',
      borderColor: 'transparent',
      color: 'black',
      fontSize: '16px',
      gap: '20px',fontWeight:'700',marginRight:'5%'
    }
  }, "& .buttonGroup button": {
    color: '#524948',
    borderColor: '#524948',
    fontSize: '14px', padding: '6px 12px',fontWeight:'400',
    "@media(max-width:768px)": {
      color: '#524948',
      borderColor: '#524948',
      fontSize: '14px', padding: '6px 12px',fontWeight:'400'
    }
  }, "& .buttonGroup .buttonClk": {
    color: 'white',
    backgroundColor: '#524948',
  },
  "& .seconMnuHandel": {
    textAlign: 'center',
    display: 'flex',
    gap: '0px',
    justifyContent: 'center',
    flexDirection: "column",
    alignItem: "center",
    width: '650px',
    "@media(max-width:768px)": {
      width: '100%',rowGap:'0px'
    }
  },
  "& .seconMnuHandelPera": {
    marginTop: '0px',marginBottom:'10px',
    width: '600px',
    "@media(max-width:768px)": {
      width: '100%', display: 'flex',
      justifyContent: 'center',
      alignItem: "center", 
      marginTop: '-15px',
    }
  },
  "& .seconMnuHead": {
    width: '600px',
    color: '#524948',
    paddingTop: '0',
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: 'Inter',
    "@media(max-width:768px)": {
      paddingTop: '0',
      width: '100%',
      fontSize: '30px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color: '#524948',
    }
  },
  "& .mainFormPageQuz": {
    backgroundColor: '#F1ECE4',
    backgroundImage: `url(${radZigImg}),  url(${crclr})`,
    backgroundPosition: 'right 150px,  -150px -100px',
    backgroundSize: '380.31px auto,  272px 272px',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    "@media(max-width:768px)": {
      padding: '0 0px', width: '100%',
      height: '100vh',
      backgroundColor: '#F1ECE4',
      backgroundImage: `url(${radZigImg}),  url(${crclr})`,
      backgroundPosition: ' 213px 80px,  -150px -100px',
      backgroundSize: '240.5px auto,  272px 272px',
      backgroundRepeat: 'no-repeat',
    }
  },
  "& .showOnMoblorDektLogo": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
  },
  "& .showOnMoblorDektLogo img": {
    width: '233.36px', height: '73.3px',
    "@media(max-width:768px)": {
      width: '106px', height: '33.3px',
    }
  },
  "& .QuzAllCont": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
    flexDirection: "column", textAlign: 'center',
    width: '100%', paddingTop: '0px', marginTop: '0px',
    "@media(max-width:768px)": {
      width: '99%',
      overflowX: 'hidden',
    }
  },
  "& .quzeModel": {
    backgroundColor: '#524948',
    borderTopLeftRadius: '50px', borderTopRightRadius: '50px', margin: ' 50px auto 0 auto', width: '95%', padding: '20px 5px 30px',
    "@media(max-width:768px)": {
      padding: '30px 0px 15px 0px',width: '100%',
      height:'640px',      overflowY: 'hidden',margin: ' 30px auto 0 auto'
    }
  },
  "& .logoTxtQuSkp": {
    margin: 'auto', width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', fontFamily: 'Inter', rowGap: '5px',
    "@media(max-width:768px)": {
      width: '100%',
    }
  }
  , "& .logoTxtQuSkpThrd": {
    textDecoration: 'underline', cursor: 'ponter',
    fontSize: '18px',
    fontWeight: '700', fontFamily:'Inter',
    "@media(max-width:768px)": {
      fontSize: '12px',
      fontWeight: '700', }
  },
  "& .quzeQ ": {
    margin: 'auto', width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', fontFamily: 'Inter', rowGap: "2px",
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  "& .quzeQ h3": {
    color: "white", fontSize: "32px", fontWeight: '700',
    "@media(max-width:768px)": {
      fontSize: "20px", padding: '55px 2px 0px'
    }
  },'& .votFrQt':{
    fontSize: '25px',
    fontWeight: '400', fontFamily:'Inter',
    "@media(max-width:768px)": {
      fontSize: '15px',
      fontWeight: '400', }
  }, "& .votSelecSld": {
    margin: '50px auto 10px auto', width: '50%', display: 'flex', justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', textAlign: 'center',
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  "& .prvNxtBtn": {
    margin: '20px auto', width: '85%', display: 'flex', justifyContent: 'space-between',
    alignItem: "center", color: '#F1ECE4',
    "@media(max-width:768px)": {
      width: '100%', margin: '50px auto 0px auto'
    }
  }
  , "& .prvNxtBtn button": {
    backgroundColor: 'transparent', border: 'none', color: '#F1ECE4', cursor: 'pointer',
    width: '51px',height:'51px',
    "@media(max-width:768px)": {
      width: '33.11px',height:'32.27px'    }
  }, 
  "& .prvNxtBtn button .prvNxtBtnb": {
    color: '#F1ECE4', cursor: 'pointer',
    width: '28.42px',height:'32.25px',
    "@media(max-width:768px)": {
      width: '33.11px',height:'32.27px'    }
  }


  

})
// Customizable Area End

Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = 'bx_block_terms_and_conditions/terms_and_conditions';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';

exports.dateFormat = "MM-DD-YYYY";
exports.title = "Title";
exports.subtitle = "Subtitle";
exports.paragraph1 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks."
exports.paragraph2 = "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Sed aliquam, nisi quis porttitor congue, elit erat euismod orci, ac placerat dolor lectus quis orci. Phasellus consectetuer vestibulum elit. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Vestibulum fringilla pede sit amet augue. In turpis. Pellentesque posuere. Praesent turpis. Aenean posuere, Nullam sagittis.";
exports.paragraph3 = "Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante.";

exports.getTermsConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
// Customizable Area End